import * as React from "react";
import { useState } from "react";
import { InputValidator } from "helpers/InputValidator";
import { Auth, useAuth } from "hooks/Auth";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, Tooltip, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from "components/common/pages/set-password-page/SetPasswordPage.module.scss";
import { RouteStrings } from "routing/Routes";
import { SetPasswordPurpose } from 'types/types';

export const SetPasswordPage = (props) => {
    const token = props.match.params.token;
    const purpose: SetPasswordPurpose = props.match.params.purpose;
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);
    const history = useHistory();
    const auth: Auth = useAuth(false);

    const PasswordInfo = () => {
        return (
            <Card title={translation["passwordRules"]["title"]} size="small" bordered={false}>
                <div className={styles.passwordInfo}>
                    {translation["passwordRules"]["description"]}
                    <br></br>
                    {translation["passwordRules"]["ruleOne"]}
                    <br></br>
                    {translation["passwordRules"]["ruleTwo"]}
                    <br></br>
                    {translation["passwordRules"]["ruleThree"]}
                    <br></br>
                    {translation["passwordRules"]["ruleFour"]}
                </div>
            </Card>
        );
    }

    const [password, setPassword] = useState(null);
    const [passwordRepeat, setPasswordRepeat] = useState(null);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(null);
    const [error, setError] = useState(null);

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 24 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 16 },
    };

    const checkPasswordMatch = () => {
        if (password === passwordRepeat) {
            return true;
        }
        setError(translation["errorMessages"]["passwordDoNotMatch"]);
        return false;
    }

    const checkPasswordCorrectFormat = () => {
        let passwordValidationResult = InputValidator.validatePassword(password);

        if (!passwordValidationResult.validationError) {
            return true;
        }
        setError(translation["errorMessages"]["passwordInvalid"]);
        return false;
    }

    const handleSetPassword = async () => {
        if (!termsAndConditionsAccepted && purpose === SetPasswordPurpose.Register) {
            setError(translation["errorMessages"]["termsAndConditionsNotAccepted"]);
        } else {
            try {
                const passwordsMatch = checkPasswordMatch();
                const passwordCorrectFormat = checkPasswordCorrectFormat();
                if (passwordsMatch && passwordCorrectFormat) {
                    let setPasswordSuccessful = purpose === SetPasswordPurpose.Register ? await auth.register(token, password) : await auth.resetPassword(token, password);
                    if (setPasswordSuccessful) {
                        history.push(RouteStrings.createSetPasswordSuccessfulUrl(purpose));
                    } else {
                        setError(translation["errorMessages"]["registrationError"]);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className={styles.page}>
            <div className={styles.logo}></div>
            {purpose === SetPasswordPurpose.Register ?
                <Button data-cy="button_submit" className={`${styles.button} ${styles.topRight}`} type="primary" htmlType="submit" onClick={() => window.open("https://get.teylor.io/de/fincr/")}>
                    {translation["signUp"]}
                </Button>
                :
                null}

            <Form className={styles.form}
                {...layout}
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
            >
                <div className={styles.title}>{purpose === SetPasswordPurpose.Register ? translation["fincrCreateAccount"] : translation["fincrResetPassword"]}</div>
                <div className={styles.description}>{purpose === SetPasswordPurpose.Register ? translation["createAccountDescription"] : translation["resetPasswordDescription"]}</div>


                <div className={styles.password}>
                    <Form.Item
                        className={styles.formItem}
                        label={translation["forms"]["labels"]["password"]}
                        name="password"
                        rules={[{ required: true, message: translation["forms"]["errors"]["password"] }]}
                    >
                        <Input.Password data-cy="input_password" className={styles.inputPassword} onChange={(e) => { setPassword(e.target.value); }} />
                    </Form.Item>
                    <Tooltip title={<PasswordInfo />} color="white">
                        <InfoCircleOutlined className={styles.icon} />
                    </Tooltip>
                </div>

                <Form.Item
                    className={styles.formItem}
                    label={translation["forms"]["labels"]["passwordRepeat"]}
                    name="password-repeat"
                    rules={[{ required: true, message: translation["forms"]["errors"]["password"] }]}
                >
                    <Input.Password data-cy="input_password" className={styles.inputPassword} onChange={(e) => { setPasswordRepeat(e.target.value); }} />
                </Form.Item>

                {purpose === SetPasswordPurpose.Register ?
                    <Checkbox className={styles.text} onChange={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}>
                        Ich habe die <a href={process.env.REACT_APP_LINK_TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">AGB</a> und <a href={process.env.REACT_APP_LINK_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> gelesen und akzeptiere beide
                    </Checkbox>
                    :
                    null
                }

                <Form.Item {...tailLayout}>
                    <Button data-cy="button_submit" className={styles.button} type="primary" htmlType="submit" onClick={() => { handleSetPassword(); }}>
                        {translation["login"]}
                    </Button>
                </Form.Item>

                {error ? <div className={styles.error}>{error}</div> : <div className={styles.error} />}
            </Form>
        </div >
    );
};