import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./AdminUserManagementPage.module.scss";
import { Tabs, Spin } from "antd";
import { PageHeader } from "components/common/presenters/page-header/PageHeader";
import { UserAccountManagement } from "components/admin/presenters/user-account-management/UserAccountManagement";
import { BackNavigation } from "components/common/presenters/back-navigation/BackNavigation";
import { UserProfile } from "types/types";
import ApiManager from "services/api/ApiManager";
import { OcrProgressTable } from "components/common/presenters/ocr-progress-table/OcrProgressTable";
import { RouteStrings } from "routing/Routes";

const { TabPane } = Tabs;

export const AdminUserManagementPage = (props) => {
	const userId = props.match.params.userId;

	const { i18n } = useTranslation("common");
	const translation = i18n.getDataByLanguage(i18n.language)["adminPanel"];
	const history = useHistory();

	const [user, setUser] = React.useState<UserProfile>({
		ID: "",
		Email: "",
		FirstName: "",
		LastName: "",
		CreatedAt: "",
		IsEnabled: null,
		IsDeleted: null,
		is_registered: null,
		company_name: "",
		company_id: "",
	});
	const [isLoading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userResult = await ApiManager.AdminService.getUserById(
					userId
				);
				setUser(userResult.data);
				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		};

		fetchUserData();
	}, [userId]);

	return isLoading ? (
		<div className={styles.spinContainer}>
			<Spin />
		</div>
	) : (
		<div className={styles.page}>
			<BackNavigation navigateToUrl={RouteStrings.AdminUsers} />
			<PageHeader
				title={`${translation["customer"]}: ${user.FirstName} ${user.LastName}`}
				subtitle={`${translation["userID"]}: `}
				copySubtitle={true}
				copyText={`${user.ID}`}
			/>
			<Tabs
				className={styles.tabs}
				activeKey={props.tab}
				id="tabs"
				onTabClick={(key, event) =>
					history.push(
						RouteStrings.createUserManagementTabUrl(userId, key)
					)
				}
			>
				<TabPane
					key="account-management"
					tab={translation["accountInformation"]}
				>
					{props.tab === "account-management" && (
						<UserAccountManagement
							userId={userId}
							userEmail={user.Email}
							isEnabled={user.IsEnabled}
						/>
					)}
				</TabPane>
				<TabPane key="documents" tab={translation["documents"]}>
					{props.tab === "documents" && (
						<OcrProgressTable isAdmin={true} userId={userId} />
					)}
				</TabPane>
				<TabPane key="billing" tab={translation["billing"]}></TabPane>
			</Tabs>
		</div>
	);
};
