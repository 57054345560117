/**
 * Global variables should go here.
 */
export const INPUTS_MAX_LENGTHS = {
	PHONE_INPUT: 22,
	DETAILED_LOAN_PURPOSE: 255
};

export const INPUTS_MIN_LENGTHS = {
    PHONE_INPUT: 8 // Solaris API phone number min length is 8 characters (plus sign and 7 digits)
};

export const PHONE_INPUT_FORMATTER_DEFAULT_LOCALE = "DE"; // so the library knows to add +49 before the formatted phone number

export const FINANCIAL = {
	MIN_NEGATIVE_VALUE: -1000000000,
	MIN_POSITIVE_VALUE: 0,
	MAX_POSITIVE_VALUE: 1000000000
};

export const COMPANY_SEARCH = {
	SEARCH_DELAY_MS: 400,
	SHOW_RESULTS_DELAY_MS: 400,
	NUMBER_OF_ROWS: 4
};

export const NETWORK_REQUEST_TIMEOUT_MS = 30000;

export const ADMIN = {
	APPLICATIONS_PIPELINE_PERIOD_DAYS: 21, // in the pipeline only show applications created in the last n days
	USER_EMAIL_SEARCH_CHAR_THRESHOLD: 3 // min. 3 chars need to be typed in the users table to start the search
};

export const TEST_DATA = {
	TEST_COMPANY_NAME_1: "Kundentestsystem Technik",
	TEST_COMPANY_NAME_2: "Test062018",
};

export const DOCUMENT_UPLOAD = {
	MAX_ALLOWED_NUMBER_OF_FILES: 24,
	MAX_ATTACHMENT_SIZE_MB: 200 * 1000000
};

export const LOAN = {
	MIN_AMOUNT_EUR: 20000,
	MAX_AMOUNT_EUR: 450000,
	ADMIN_MAX_AMOUNT_EUR: 450000,
	KFW_MAX_AMOUNT_EUR: 800000,
	MIN_DURATION_MONTHS: 6,
	MAX_DURATION_MONTHS: 60,
	KFW_MAX_DURATION_MONTHS: 120,
	LOAN_INFO_PAGE_DEFAULT_DURATION: 48,
	LOAN_INFO_PAGE_DEFAULT_AMOUNT: 100000,
	SLIDER_STEP_EUR: 10000,
	SLIDER_STEP_MONTHS: 1
};

export const DATE_FORMAT = {
	API: 'YYYY-MM-DD',
	DE: 'DD.MM.YYYY' // TODO move to translations
};

// TODO: rework for AKF
export const getMaxLoanAmount = (isAdmin, isKfw) => {
	if (isKfw) {
		return LOAN.KFW_MAX_AMOUNT_EUR;
	}
	if (isAdmin) {
		return LOAN.ADMIN_MAX_AMOUNT_EUR;
	}
	
	return LOAN.MAX_AMOUNT_EUR;
};

export const getMaxDuration = (isKfw) => {
	if (isKfw) {
		return LOAN.KFW_MAX_DURATION_MONTHS;
	}
	
	return LOAN.MAX_DURATION_MONTHS;
};

export const getMonths = (isKfw) => {
	if (isKfw) {
		return  [6, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120];
	}
	
	return  [6, 12, 24, 36, 48, 60];
};
