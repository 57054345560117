import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./OcrProgressTable.module.scss";
import { Button, Dropdown, Menu, Popconfirm } from "antd";

import {
    DownloadOutlined,
    DownOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import ApiManager from "services/api/ApiManager";

interface IProps {
    documentId: string;
    isCompleted: boolean;
    userId?: string;
    onDocumentDelete?: () => void;
    isAdmin?: boolean;
}

const FileActions: React.FC<IProps> = ({
    documentId,
    isCompleted,
    userId,
    onDocumentDelete,
    isAdmin
}) => {
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);

    const getJsonDownloadUrl = (documentId) => {
        if (isAdmin) {
            return `${process.env.REACT_APP_USER_SERVICE_URL}/admin/documents/${documentId}/results`;
        } else {
            return `${process.env.REACT_APP_USER_SERVICE_URL}/fu/documents/${documentId}/results`;
        }
    };

    const getXlsxDownloadUrl = (documentId) => {
        if (isAdmin) {
            return `${process.env.REACT_APP_USER_SERVICE_URL}/admin/documents/${documentId}/results?format=xlsx`;
        } else {
            return `${process.env.REACT_APP_USER_SERVICE_URL}/fu/documents/${documentId}/results?format=xlsx`;
        }
    };

    const getOriginalFileDownloadUrl = async () => {
        const result = await ApiManager.AdminService.getPdfDownloadUrl(userId, documentId);
        window.open(result.data.url, '_self');
    };

    const CustomJsonMenuItem = (props) => {
        return (
            <a href={getJsonDownloadUrl(documentId)}>
                <Menu.Item key="0" className={styles.menuItem} {...props}>
                    .JSON
                </Menu.Item>
            </a>
        );
    }

    const CustomXlsxMenuItem = (props) => {
        return (
            <a href={getXlsxDownloadUrl(documentId)}>
                <Menu.Item key="1" className={styles.menuItem} {...props}>
                    .XLSX
                </Menu.Item>
            </a>
        );
    }

    const CustomOriginalFileMenuItem = (props) => {
        return (
            <Menu.Item key="2" className={styles.menuItem} {...props} onClick={getOriginalFileDownloadUrl}>
                Original File
            </Menu.Item>
        );
    }

    let menu = null;

    if (isAdmin) {
        menu = (
            <Menu>
                <CustomJsonMenuItem />
                <Menu.Divider />
                <CustomXlsxMenuItem />
                <Menu.Divider />
                <CustomOriginalFileMenuItem />
            </Menu>
        );
    } else {
        menu = (
            <Menu>
                <CustomJsonMenuItem />
                <Menu.Divider />
                <CustomXlsxMenuItem />
            </Menu>
        );
    }

    return (
        <div className={styles.buttonsContainer}>
            {/* <Link to={RouteStrings.createDocumentReviewUrl(documentId)}>
				<Button
					disabled={!isCompleted}
					type="primary"
					icon={<SearchOutlined />}
				>
					{translation["review"]}
				</Button>
			</Link> */}
            {isCompleted ? (
                <Dropdown overlay={menu} trigger={["click"]}>
                    <Button type="primary" icon={<DownloadOutlined />}>
                        {translation["download"]} <DownOutlined />
                    </Button>
                </Dropdown>
            ) : (
                <Button disabled type="primary" className={styles.buttonDisabled} icon={<DownloadOutlined />}>
                    {translation["download"]} <DownOutlined />
                </Button>
            )}
            {onDocumentDelete ?
                <Popconfirm
                    title={<div className={styles.confirmationDialogueText}>{translation['deleteDocModalQuestion']}</div>}
                    okText={<div className={styles.confirmationDialogueText} style={{ color: 'white' }}>{translation['yes']}</div>}
                    cancelText={<div className={styles.confirmationDialogueText}>{translation['no']}</div>}
                    onConfirm={onDocumentDelete}
                >
                    <Button type="text" icon={<DeleteOutlined className={styles.deleteIcon} />} />
                </Popconfirm>
                :
                null
            }

        </div>
    );
};

export default FileActions;
