import * as React from "react";
import { useState } from "react";
import { Auth, useAuth } from "hooks/Auth";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import styles from "components/common/pages/authentication-page/AuthenticationPage.module.scss";
import { RouteStrings } from "routing/Routes";

export const AuthenticationPage = (props) => {
    const isAdmin = props.match.path === RouteStrings.AdminLogin;
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);
    const auth: Auth = useAuth(isAdmin);
    const history = useHistory();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loginNotSuccessful, setLoginNotSuccessful] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 16 },
    };

    const handleLogin = async () => {
        try {
            const isLoginSuccessful = await auth.logIn(email, password, isAdmin);
            if (isLoginSuccessful) {
                history.push(isAdmin ? RouteStrings.AdminHome : RouteStrings.Home);
            } else {
                setLoginNotSuccessful(true);
            }
        } catch (error) {
            console.error(error);
            setLoginNotSuccessful(true);
        }
    };

    return (
        <div className={styles.page}>
            <div className={styles.logo}></div>

            {isAdmin ?
                null
                :
                <Button
                    data-cy="button_submit"
                    className={`${styles.button} ${styles.topRight}`}
                    type="primary"
                    htmlType="submit"
                    onClick={() => window.open("https://get.teylor.io/de/fincr/")}
                >
                    {translation["signUp"]}
                </Button>
            }

            <Form className={styles.form}
                {...layout}
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
            >
                <div className={styles.title}>{isAdmin ? translation["adminPanel"]["finscanAdminLogin"] : translation["finscanLogin"]}</div>

                <Form.Item
                    className={styles.formItem}
                    label={translation["forms"]["labels"]["email"]}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: translation["forms"]["errors"]["email"],
                        },
                    ]}
                >
                    <Input
                        data-cy="input_email"
                        className={styles.inputEmail}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    className={styles.formItem}
                    label={translation["forms"]["labels"]["password"]}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: translation["forms"]["errors"]["password"],
                        },
                    ]}
                >
                    <Input.Password
                        data-cy="input_password"
                        className={styles.inputPassword}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                </Form.Item>

                {isAdmin ?
                    null
                    :
                    <div className={styles.text}>
                        {translation["noAccount"]} <a href="https://get.teylor.io/de/fincr/" target="_blank" rel="noreferrer">{translation["registerHere"]}</a>
                    </div>
                }

                <Form.Item {...tailLayout}>
                    <Button
                        data-cy="button_submit"
                        className={styles.button}
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            handleLogin();
                        }}
                    >
                        {translation["login"]}
                    </Button>
                </Form.Item>

                {loginNotSuccessful ? (
                    <div className={styles.error}>
                        {translation["loginError"]}
                    </div>
                ) : <div className={styles.error} />}
            </Form>
        </div>
    );
};
