import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Store, useStore } from "hooks/Store";
import styles from "./HomePage.module.scss";
import { PageHeader } from "components/common/presenters/page-header/PageHeader";
import { DocumentUpload } from "components/common/presenters/document-upload/DocumentUpload";
import { OcrProgressTable } from "components/common/presenters/ocr-progress-table/OcrProgressTable";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const HomePage = (props) => {
	let history = useHistory();
	const { i18n } = useTranslation("common");
	const translation = i18n.getDataByLanguage(i18n.language);
	const store: Store = useStore();

	return (
		<div className={styles.page}>
			<PageHeader
				title={translation["finscanTitle"]}
				subtitle={`${translation["user"]}: ${store.data.profile.Email}`}
			/>
			<Tabs
				className={styles.tabs}
				activeKey={props.tab}
				id="tabs"
				onTabClick={(key, event) => history.push(`/home/${key}`)}
			>
				<TabPane key="upload" tab={translation["upload"]}>
					{props.tab === "upload" && <DocumentUpload />}
				</TabPane>
				<TabPane key="ocr" tab={translation["ocr"]}>
					{props.tab === "ocr" && (
						<OcrProgressTable isAdmin={false} />
					)}
				</TabPane>
			</Tabs>
		</div>
	);
};
