import * as React from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Button } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import styles from "./SetPasswordSuccessfulPage.module.scss";
import { RouteStrings } from "routing/Routes";
import { SetPasswordPurpose } from "types/types";

export const SetPasswordSuccessfulPage = (props) => {
    const purpose: SetPasswordPurpose = props.match.params.purpose;
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);
    const history = useHistory();

    return (
        <div className={styles.page}>
            <div className={styles.logo}></div>

            {purpose === SetPasswordPurpose.Register ?
            <Button data-cy="button_submit" className={`${styles.button} ${styles.topRight}`} type="primary" htmlType="submit" onClick={() => window.open("https://get.teylor.io/de/fincr/")}>
                {translation["signUp"]}
            </Button>
            :
            null}

            <div className={styles.form}>
                <div className={styles.text}>{purpose === SetPasswordPurpose.Register ? translation["feedbackMessages"]["userAccountCreatedSuccessful"] : translation["feedbackMessages"]["passwordResetSuccessful"]}</div>

                <CheckCircleTwoTone className={styles.successIcon} />
                <div className={styles.textSecondary}>{translation["feedbackMessages"]["loginPrompt"]}</div>

                <Button data-cy="button_submit" className={styles.button} type="primary" htmlType="submit" onClick={() => history.push(RouteStrings.Login)}>
                    {translation["toLoginPage"]}
                </Button>
            </div>
        </div>
    );
};