import * as React from "react";
import { Auth, useAuth } from "hooks/Auth";
import { useHistory } from "react-router-dom";
import { RouteStrings } from "routing/Routes";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import {
	FileAddOutlined,
	UserOutlined,
	PoweroffOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import styles from './SideMenu.module.scss';

// TODO - replace this custom component with the side menu from antd!!!
export const SideMenu = (props) => {
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);
    const auth: Auth = useAuth(props.isAdmin);
    const history = useHistory();

    const renderAdminMenuItems = () => {
		const menuItems = [
			{
				key: "iconUsers",
				name: translation["users"],
				link: RouteStrings.AdminUsers,
				icon: (selected) => (
					<UserOutlined
						className={styles.icon}
						style={{ color: selected ? "#ffffff" : "#4e4e4e" }}
					/>
				),
			},
		];

		const markup = [];

		for (let i = 0; i < menuItems.length; i++) {
			const highlightedForURLs = new RegExp(
				`${menuItems[i].link}(/[^/]*)*`
			);
			markup.push(
				<div className={styles.menuItem} key={i}>
					<Link to={menuItems[i].link}>
						<Tooltip
							mouseEnterDelay={1.5}
							placement="left"
							title={menuItems[i].name}
						>
							{history.location.pathname.match(
								highlightedForURLs
							) &&
							history.location.pathname.match(
								highlightedForURLs
							)[0] === history.location.pathname
								? menuItems[i].icon(true)
								: menuItems[i].icon(false)}
						</Tooltip>
					</Link>
				</div>
			);
		}

		return markup;
	};

	const renderUserMenuItems = () => {
		const menuItems = [
			{
				key: "iconFAQ",
				name: translation["faq"],
				link: RouteStrings.HomeUpload,
				icon: (selected) => (
					<FileAddOutlined
						className={styles.icon}
						style={{ color: selected ? "#ffffff" : "#4e4e4e" }}
					/>
				),
			},
			{
				key: "iconFAQ",
				name: translation["faq"],
				link: RouteStrings.FAQ,
				icon: (selected) => (
					<QuestionCircleOutlined
						className={styles.icon}
						style={{ color: selected ? "#ffffff" : "#4e4e4e" }}
					/>
				),
			},
		];

		const markup = [];

		for (let i = 0; i < menuItems.length; i++) {
			const highlightedForURLs = new RegExp(
				`${menuItems[i].link}(/[^/]*)*`
			);
			markup.push(
				<div className={styles.menuItem} key={i}>
					<Link to={menuItems[i].link}>
						<Tooltip
							mouseEnterDelay={1.5}
							placement="left"
							title={menuItems[i].name}
						>
							{history.location.pathname.match(
								highlightedForURLs
							) &&
							history.location.pathname.match(
								highlightedForURLs
							)[0] === history.location.pathname
								? menuItems[i].icon(true)
								: menuItems[i].icon(false)}
						</Tooltip>
					</Link>
				</div>
			);
		}

		return markup;
	};

    const handleLogout = () => {
        auth.logOut();
        if (props.isAdmin) {
            history.push(RouteStrings.AdminLogin);
        } else {
            history.push(RouteStrings.Login);
        }
    }

    return (
		<div className={styles.sideMenu}>
			<div className={styles.cornerTop}></div>
			<div className={styles.cornerBottomContainer}>
				<div className={styles.cornerBottom}></div>
			</div>

			<div className={styles.logo}></div>

			{props.isAdmin ? renderAdminMenuItems() : renderUserMenuItems()}

			<div className={styles.containerBottom}>
				<div
					className={styles.menuItem}
					onClick={() => {
						handleLogout();
					}}
				>
					<PoweroffOutlined
						className={styles.icon}
						style={{ color: "#F5222D" }}
					/>
					<div className={styles.label}>logout</div>
				</div>
			</div>
		</div>
	);
};
