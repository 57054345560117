import ApiManager from "../services/api/ApiManager";
import { message } from "antd";
import { Store, useStore } from "./Store";
import { UserProfile } from "types/types";

export interface Auth {
    profile: UserProfile
    logIn: (username: string, password: string, isAdmin: boolean) => Promise<boolean>
    logOut: () => Promise<void>
    register: (token: string, password: string) => Promise<boolean>
    checkAuth: () => Promise<boolean>
    loopRefreshSession: () => Promise<void>
    resetPassword: (token: string, password: string) => Promise<boolean>
}

export const useAuth = (isAdmin): Auth => {
    const store: Store = useStore();

    const register = async (token: string, password: string): Promise<boolean> => {
        try {
            const result = await ApiManager.UserService.registerUser(token, password);
            if (result.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const logIn = async (email: string, password: string): Promise<boolean> => {
        try {
            let loginResult;
            if (isAdmin) {
                loginResult = await ApiManager.AdminService.loginUser(email, password);
            } else {
                loginResult = await ApiManager.UserService.loginUser(email, password);
            }

            if (loginResult.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const logOut = async () => {
        try {
            const existingTimeoutId = loadTimeoutId()
            clearTimeout(existingTimeoutId)

            store.reset()
            if (isAdmin) {
                await ApiManager.AdminService.logoutUser();
            } else {
                await ApiManager.UserService.logoutUser();
            }
        } catch (e) {
            console.error(e)
            message.error("Error during logout.")
        }
    }

    const checkAuth = async (): Promise<boolean> => {
        try {
            let profileResponse;
            if (isAdmin) {
                profileResponse = await ApiManager.AdminService.checkUserAuth();
            } else {
                profileResponse = await ApiManager.UserService.checkUserAuth();
            }

            if (profileResponse.data && store.isEmpty()) {
                store.setData({
                    profile: {
                        ...profileResponse.data
                    }
                });
            }
            return true;
        } catch (error) {
            console.error(`Could not auth user: ${error.message}`);
            return false;
        }
    }

    const loopRefreshSession = async (): Promise<void> => {
        const existingTimeoutId = loadTimeoutId()

        if (existingTimeoutId) {
            clearTimeout(existingTimeoutId)
        }

        try {
            if (isAdmin) {
                await ApiManager.AdminService.refreshSession();
            } else {
                await ApiManager.UserService.refreshSession();
            }
        } catch (e) {
            console.log("session refresh failed")
            console.error(e)
            return
        }

        const timeoutId = setTimeout(loopRefreshSession, 50 * 60 * 1000)

        saveTimeoutId(timeoutId)
    }

    const resetPassword = async (token: string, password: string): Promise<boolean> => {
        try {
            const result = await ApiManager.UserService.setNewPassword(token, password);
            if (result.status === 200) {
                return true;
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }


    return {
        profile: store.data.profile,
        logIn,
        logOut,
        register,
        checkAuth,
        loopRefreshSession,
        resetPassword,
    } as Auth
}

const saveTimeoutId = (timeoutId) => {
    localStorage.setItem("session_refresh_timeout_id", timeoutId)
}

const loadTimeoutId = (): any => {
    return localStorage.getItem("session_refresh_timeout_id")
}