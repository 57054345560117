import * as React from "react";
import { Button } from 'antd';
import { useHistory } from "react-router-dom";
import styles from "./BackNavigation.module.scss";

export const BackNavigation = (props) => {
    const history = useHistory();
    return (
        <div className={styles.backNavigation}>
            <Button className={styles.button} onClick={() => { props.navigateToUrl ? history.push(props.navigateToUrl) : history.goBack() }} type="primary" icon={<div className={styles.icon} />}>Back</Button>
        </div>
    );
};
