import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./AdminUsersPage.module.scss";
import { UserProfile } from "types/types";
import { Table, Button, Input, Row, Col, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ArrowRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Formatter } from "helpers/Formatter";
import { RouteStrings } from "routing/Routes";
import ApiManager from "services/api/ApiManager";

enum USER_TABLE_COLUMN_KEYS {
	ID = "userID",
	Email = "userEmail",
	IsRegistered = "isRegistered",
	IsEnabled = "isEnabled",
	CreatedAt = "userCreatedDate",
	Link = "userLink", // Remove me eventually
	CompanyName = "companyName",
}

interface State {
	users: UserProfile[];
	totalUsersCount: number;
	isLoading: boolean;
}

export const AdminUsersPage = () => {
	const { i18n } = useTranslation("common");
	const translation = i18n.getDataByLanguage(i18n.language)["adminPanel"];

	const [state, setState] = React.useState<State>({
		users: [],
		totalUsersCount: 0,
		isLoading: true,
	});

	const [typingTimeout, setTypingTimeout] = React.useState(null);
	const [emailSearchValue, setEmailSearchValue] = React.useState("");
	const [pageNumber, setPageNumber] = React.useState(1);

	React.useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (typingTimeout) clearTimeout(typingTimeout);
		let timeout = setTimeout(async () => {
			fetchData();
		}, 300); // wait 300ms on each user key press to trigger the search function
		setTypingTimeout(timeout);
	}, [emailSearchValue, pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	const fetchData = async () => {
		try {
			let usersResult;
			if (emailSearchValue) {
				usersResult = await ApiManager.AdminService.getAllUsers(
					pageNumber,
					10,
					emailSearchValue
				);
			} else {
				usersResult = await ApiManager.AdminService.getAllUsers(
					pageNumber,
					10
				);
			}
			setState({
				...state,
				users: usersResult.data.users,
				totalUsersCount: usersResult.data.total_count,
				isLoading: false,
			});
		} catch (error) {
			console.error(error);
		}
	};

	const tableColumns: ColumnsType<UserProfile> = [
		{
			key: "id",
			title: translation["usersTable"][
				USER_TABLE_COLUMN_KEYS.ID
			].toUpperCase(),
			dataIndex: "ID",
		},
		{
			title: translation["usersTable"][
				USER_TABLE_COLUMN_KEYS.Email
			].toUpperCase(),
			dataIndex: "Email",
		},
		{
			title: translation["usersTable"][
				USER_TABLE_COLUMN_KEYS.CompanyName
			].toUpperCase(),
			dataIndex: "company_name",
			render: (field: string, record: UserProfile) => {
				return (
					<Link to={"/admin/companies/" + record.company_id}>
						{field}
					</Link>
				);
			},
		},
		{
			title: translation["usersTable"][
				USER_TABLE_COLUMN_KEYS.IsRegistered
			].toUpperCase(),
			dataIndex: "is_registered",
			render: (record: boolean) => {
				return record ? (
					<Tag color="green">Yes</Tag>
				) : (
					<Tag color="blue">No</Tag>
				);
			},
		},
		{
			title: translation["usersTable"][
				USER_TABLE_COLUMN_KEYS.IsEnabled
			].toUpperCase(),
			dataIndex: "IsEnabled",
			render: (record: boolean) => {
				return record ? (
					<Tag color="green">Yes</Tag>
				) : (
					<Tag color="red">No</Tag>
				);
			},
		},
		{
			title: translation["usersTable"][
				USER_TABLE_COLUMN_KEYS.CreatedAt
			].toUpperCase(),
			dataIndex: "CreatedAt",
			render: (record: string) => {
				return Formatter.formatTimestampIntoZurichTime(record);
			},
		},
		{
			title: "LINK",
			dataIndex: "ID",
			render: (record: string) => {
				let s = RouteStrings.AdminUsers + "/" + record;
				return (
					<Link to={s}>
						<Button
							className={styles.button}
							type="link"
							shape="circle"
							icon={<ArrowRightOutlined />}
						/>
					</Link>
				);
			},
		},
	];

	const locale = {
		emptyText: translation["noData"],
	};

	const itemRender = (current, type, originalElement) => {
		if (type === "next") {
			return (
				<div className={styles.tablePaginationNextButton}>
					{translation["next"]}
				</div>
			);
		}
		return originalElement;
	};

	return state.isLoading ? (
		<div className={styles.spinContainer}>
			<Spin />
		</div>
	) : (
		<>
			<div className={styles.container}>
				<Row>
					<div className={styles.title}>
						{translation["usersTable"]["allUsers"]}
					</div>
				</Row>
				<Row>
					<Col span={12}>
						<Input
							className={styles.searchBar}
							placeholder="Search by email"
							allowClear
							value={emailSearchValue}
							prefix={
								<SearchOutlined className={styles.searchIcon} />
							}
							onChange={(event) =>
								setEmailSearchValue(event.target.value)
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Table
							id="users"
							className={styles.table}
							locale={locale}
							dataSource={state.users}
							columns={tableColumns}
							rowClassName={(record, index) =>
								index % 2 === 0
									? "table-row-dark"
									: "table-row-light"
							}
							pagination={{
								position: ["bottomLeft"],
								defaultPageSize: 10,
								total: state.totalUsersCount,
								showSizeChanger: false,
								itemRender: itemRender,
								onChange: (pageNumber) =>
									setPageNumber(pageNumber),
							}}
						/>
					</Col>
				</Row>
			</div>
		</>
	);
};
