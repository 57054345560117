import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { AuthenticationPage } from "components/common/pages/authentication-page/AuthenticationPage";
import { SetPasswordPage } from "components/common/pages/set-password-page/SetPasswordPage";
import { SetPasswordSuccessfulPage } from "components/common/pages/set-password-successful-page/SetPasswordSuccessfulPage";
import { HomePage } from "components/common/pages/home-page/HomePage";
import { AdminUsersPage } from "components/admin/pages/admin-users-page/AdminUsersPage";
import { AdminUserManagementPage } from "components/admin/pages/admin-user-management-page/AdminUserManagementPage";
import { DocumentReviewPage } from "components/common/pages/document-review-page/DocumentReviewPage";
import { FAQPage } from "components/common/pages/faq-page/FAQPage";
import {
	AuthenticatedRoute,
	AuthenticatedAdminRoute,
} from "./AuthenticatedRoute";

export class RouteStrings {
	// User routes
	public static Index = "/";
	public static Login = "/login";
	public static AdminLogin = "/admin/login";
	public static SetPassword = "/fu/account/:purpose/:token";
	public static SetPasswordSuccessful = "/:purpose/successful";
	public static Home = "/home";
	public static HomeUpload = "/home/upload";
	public static HomeOcr = "/home/ocr";
	public static DocumentReview = "/document-review/:documentId";
	public static FAQ = "/faq";

	// Admin routes
	public static AdminHome = "/admin/home";
	public static AdminUsers = "/admin/fusers";
	public static AdminUserManagment = "/admin/fusers/:userId";
	public static AdminUserManagmentTab = "/admin/fusers/:userId/:tab";
	public static AdminUserAccountManagement =
		"/admin/fusers/:userId/account-management";
	public static AdminUserDocuments = "/admin/fusers/:userId/documents";
	public static AdminUserBilling = "/admin/fusers/:userId/billing";

	public static NoAuthRoutes = [
		RouteStrings.Login,
		RouteStrings.Index,
		RouteStrings.AdminLogin,
	];

	public static createDocumentReviewUrl = (documentId: string): string =>
		RouteStrings.DocumentReview.replace(":documentId", documentId);
	public static createUserManagementTabUrl = (userId, tab: string): string =>
		RouteStrings.AdminUserManagmentTab.replace(":userId", userId).replace(
			":tab",
			tab
		);
	public static createUserAccountManagementUrl = (userId: string): string =>
		RouteStrings.AdminUserAccountManagement.replace(":userId", userId);
	public static createUserDocumentsUrl = (userId: string): string =>
		RouteStrings.AdminUserDocuments.replace(":userId", userId);
	public static createUserBillingUrl = (userId: string): string =>
		RouteStrings.AdminUserBilling.replace(":userId", userId);
	public static createSetPasswordSuccessfulUrl = (purpose: string): string =>
		RouteStrings.SetPasswordSuccessful.replace(":purpose", purpose);
}

export const Routes: React.FC<any> = (props) => {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path={RouteStrings.Index}>
					<Redirect to={RouteStrings.Login} />
				</Route>
				<Route exact path={RouteStrings.Home}>
					<Redirect to={RouteStrings.HomeUpload} />
				</Route>
				<Route exact path={RouteStrings.AdminHome}>
					<Redirect to={RouteStrings.AdminUsers} />
				</Route>
				<Route
					exact
					path={RouteStrings.AdminUserManagment}
					render={(props) => (
						<Redirect
							to={RouteStrings.createUserAccountManagementUrl(
								props.match.params.userId
							)}
						/>
					)}
				/>
				<Route
					path={[RouteStrings.AdminLogin]}
					component={AuthenticationPage}
				/>
				<Route
					path={[RouteStrings.Login]}
					component={AuthenticationPage}
				/>
				<Route
					path={[RouteStrings.SetPassword]}
					component={SetPasswordPage}
				/>
				<Route
					path={[RouteStrings.SetPasswordSuccessful]}
					component={SetPasswordSuccessfulPage}
				/>
				<AuthenticatedAdminRoute
					exact
					path={[RouteStrings.AdminUsers]}
					component={AdminUsersPage}
					props={{}}
				/>
				<AuthenticatedAdminRoute
					path={[RouteStrings.AdminUserAccountManagement]}
					component={AdminUserManagementPage}
					props={{ tab: "account-management" }}
				/>
				<AuthenticatedAdminRoute
					path={RouteStrings.AdminUserDocuments}
					component={AdminUserManagementPage}
					props={{ tab: "documents" }}
				/>
				<AuthenticatedAdminRoute
					path={RouteStrings.AdminUserBilling}
					component={AdminUserManagementPage}
					props={{ tab: "billing" }}
				/>
				<AuthenticatedRoute
					path={RouteStrings.HomeUpload}
					component={HomePage}
					props={{ tab: "upload" }}
				/>
				<AuthenticatedRoute
					path={RouteStrings.HomeOcr}
					component={HomePage}
					props={{ tab: "ocr" }}
				/>
				<AuthenticatedRoute
					path={RouteStrings.DocumentReview}
					component={DocumentReviewPage}
					props={{}}
				/>
				<AuthenticatedRoute
					path={RouteStrings.FAQ}
					component={FAQPage}
					props={{}}
				/>
			</Switch>
		</BrowserRouter>
	);
};
