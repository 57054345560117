export interface FileObject {
	fileId: string;
	name: string;
	uploading: boolean;
	awsId: string;
	visibleForUser: boolean;
}

export interface DocumentData {
	id: string;
	category: string;
	content_type: string;
	country: string;
	created_at: string;
	current_values: any;
	language: string;
	filename: string;
	purpose: string;
	status: string;
	url: string;
}

export interface UserProfile {
	ID: string;
	Email: string;
	FirstName: string;
	LastName: string;
	CreatedAt: string;
	IsEnabled: boolean;
	IsDeleted: boolean;
	is_registered: boolean;
	company_name?: string;
	company_id: string;
}

export enum DocumentStatus {
	Init = 0,
	Failed = -1,
	TextDetection = 1,
	Analysis = 2,
	Postprocessing = 3,
	Completed = 4,
}

export enum DocumentType {
	AnnualStatement = "annual_statement",
	BWA_SUSA = "de_bwa_susa",
	None = "",
}

export enum SetPasswordPurpose {
	Register = "register",
	Reset = "reset",
}
