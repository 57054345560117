import { DocumentType } from "types/types";

export const DOCUMENT_UPLOAD = {
	MAX_ALLOWED_NUMBER_OF_FILES: 5,
	MAX_ATTACHMENT_SIZE_MB: 200 * 1000000
};

export const DOCUMENT_TYPE_NAMES = {
    [DocumentType.AnnualStatement]: "Jahresabschluss",
    [DocumentType.BWA_SUSA]: "BWA / SuSa"
}