import ApiManager from "./ApiManager";
import { ApiWrapper } from "./ApiWrapper";

const getDocs = async () => {
	const path = "/fu/documents";

	const apiCall = ApiWrapper.get(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const getDoc = async (id) => {
	const path = `/fu/documents/${id}`;

	const apiCall = ApiWrapper.get(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const deleteDoc = async (id) => {
	const path = `/fu/documents/${id}`;

	const apiCall = ApiWrapper.del(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const getDocResult = async (id) => {
	const path = `/fu/documents/${id}/result`;

	const apiCall = ApiWrapper.get(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const uploadDoc = async (userId, file, docType) => {
	const path = `/fu/documents`;

	const apiCall = ApiWrapper.post(path, {
		filename: file.name,
		document_type: docType,
		country: "de", // HARDCODED FOR NOW
		language: "de", // HARDCODED FOR NOW
		document_format: "pdf", // HARDCODED FOR NOW
	});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const setDocUploadSuccessful = async (documentId) => {
	const path = `/fu/documents/${documentId}/upload_success`;

	const apiCall = ApiWrapper.patch(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const updateDocCorrections = async (documentId, corrections) => {
	const path = `/fu/documents/${documentId}/updateCorrections`;

	const apiCall = ApiWrapper.post(path, {
		id: documentId,
		current_values: corrections,
	});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

export const DocService = {
	getDocs,
	getDoc,
	getDocResult,
	uploadDoc,
    setDocUploadSuccessful,
	updateDocCorrections,
	deleteDoc,
};
