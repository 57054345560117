import { FileObject } from "types/types";
import { INPUT_VALIDATION_ERRORS } from "./InputFormHelper";

export class InputValidationResult {
    public validationError: boolean = true;
    public errorMessage: string = null;
}

export const ALLOWED_FILE_TYPES = {
    "pdf": ""
};

export class InputValidator {
    public static validateDocTypes(fileList: FileObject[]): InputValidationResult {
        let result = new InputValidationResult();

        let invalidFileType = false;

        for (let i = 0; i < fileList.length; i++) {
            let fileType: any = fileList[i].name.split('.');

            fileType = fileType[fileType.length - 1].toLowerCase();

            invalidFileType = false;

            if (ALLOWED_FILE_TYPES[fileType] === undefined)
                invalidFileType = true;
        }

        if (!invalidFileType)
            result.validationError = false;
        else
            result.errorMessage = INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.INVALID;

        return result;
    }

    public static validateDocType(docType: string): InputValidationResult {
        let result = new InputValidationResult();

        let invalidFileType = false;

        let fileType: any = (docType.match(/\.([0-9a-z]+$)/i)[1] || '').toLowerCase();

        if (ALLOWED_FILE_TYPES[fileType] === undefined)
            invalidFileType = true;

        if (!invalidFileType)
            result.validationError = false;
        else
            result.errorMessage = INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.INVALID;

        return result;
    }

    public static validatePassword(password: string): InputValidationResult {
		let result = new InputValidationResult();

		result.validationError = true;
		result.errorMessage = INPUT_VALIDATION_ERRORS.PASSWORD.INVALID;

		// This regex checks for letters, capitals, numbers, and non-character ASCII + unicode ranges
		if (
			password &&
			password.match(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u00FF\u0100-\uFFFF]).{8,254}$/
			)
		) {
			result.validationError = false;
			result.errorMessage = null;
		}

		return result;
	}
}