import * as React from "react";
import {useTranslation} from 'react-i18next';
import styles from "./OcrProgressTable.module.scss";
import {Table, Progress, Spin} from "antd";
import {ColumnsType} from 'antd/lib/table';
import {DocumentData, DocumentStatus} from "types/types";
import {FileItem} from "components/common/presenters/file-item/FileItem";
import {CheckCircleTwoTone, CloseCircleTwoTone, CheckCircleOutlined} from '@ant-design/icons';
import FileActions from './FileActions';
import {DOCUMENT_TYPE_NAMES} from "../../../../globals";
import ApiManager from "services/api/ApiManager";
import {Formatter} from "helpers/Formatter";

const OCR_TABLE_COLUMN_KEYS = ["documentName", "step", "progress", "status", "action"];
const OCR_TABLE_ADMIN_COLUMN_KEYS = ["documentName", "documentType", "step", "scanDate", "results"];

export const OcrProgressTable = (props) => {
	const {i18n} = useTranslation("common");
	const translation = i18n.getDataByLanguage(i18n.language);

	const isDocComplete = (stat: string) => {
		if (stat === 'completed') {
			return true
		}
		return false
	}

	const getDocStatus = (stat: string) => {
		if (stat === 'text_detection') {
			return DocumentStatus.TextDetection;
		} else if (stat === 'analysis') {
			return DocumentStatus.Analysis;
		} else if (stat === 'postprocessing') {
			return DocumentStatus.Postprocessing;
		} else if (stat === 'completed') {
			return DocumentStatus.Completed;
		} else if (stat === 'failed') {
			return DocumentStatus.Failed;
		}
		// Default
		return DocumentStatus.Init;
	}

	const getTableColumns = () => {
		const columnKeys = props.isAdmin ? OCR_TABLE_ADMIN_COLUMN_KEYS : OCR_TABLE_COLUMN_KEYS;
		let tableColumns: ColumnsType<DocumentData> = [];
		columnKeys.forEach(key => {
			tableColumns.push({
				title: (
					<div className={styles[`header_${key}`]}>
						{translation[key]}
					</div>
				),
				dataIndex: key,
				key: key,
				render: (text, record) => {
					return {
						props: {
							className: styles[`cell_${key}`],
						},
						children: text
					};
				}
			});
		});
		return tableColumns;
	};

	const getTableRows = () => {
		let rows = [];

		for (let i = 0; i < documents.length; i++) {
			const document: DocumentData = documents[i];
			const isCompleted = isDocComplete(document.status);

			if (props.isAdmin) {
				rows.push(
					{
						key: i,
						documentName: (
							<FileItem
								key={`${document.id}`}
								showJustFileName={true}
								showBottomBorder={false}
								fileName={document.filename}
							/>
						),
						documentType: DOCUMENT_TYPE_NAMES[document.purpose],
						step: document.status,
						scanDate: Formatter.formatTimestampIntoZurichTime(document.created_at),
						results: <FileActions isCompleted={isDocComplete(document.status)} documentId={document.id} userId={props.userId} isAdmin={props.isAdmin} />
					}
				);
			}
			else {
				rows.push(
					{
						key: i,
						documentName: (
							<FileItem
								key={`${document.id}`}
								showJustFileName={true}
								showBottomBorder={false}
								fileName={document.filename}
							/>
						),
						step: document.status,
						progress: <Progress percent={getDocStatus(document.status) * 25} status={isCompleted ? "normal" : "active"} trailColor={getDocStatus(document.status) === DocumentStatus.Failed ? "#c6c6c6" : "#f5f5f5"} />,
						status: isCompleted ? <CheckCircleTwoTone twoToneColor="#52c41a" className={styles.statusIcon} /> : getDocStatus(document.status) === DocumentStatus.Failed ? <CloseCircleTwoTone twoToneColor="#ff7976" className={styles.statusIcon} /> : <CheckCircleOutlined className={styles.statusIcon} />,
						action: <FileActions isCompleted={isDocComplete(document.status)} documentId={document.id} onDocumentDelete={() => handleDocumentDelete(document.id)} />
					}
				);
			}
		}
		return rows;
	};

	const locale = {
		emptyText: translation["noData"]
	};

	const itemRender = (current, type, originalElement) => {
		if (type === 'next') {
			return <div className={styles.tablePaginationNextButton}>{translation["next"]}</div>;
		}
		return originalElement;
	};

	const [documents, setDocuments] = React.useState([]);
	const [isLoading, setLoading] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const docsResult = props.isAdmin ? await ApiManager.AdminService.getDocsForUser(props.userId) : await ApiManager.DocService.getDocs();
				setDocuments(docsResult.data.data);
				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		};

		let interval;
		setLoading(true);
		fetchData();
		interval = setInterval(() => {
			fetchData();
		}, 4000);
		return () => clearInterval(interval);
	}, [props]);

	const handleDocumentDelete = async (docId) => {
		try {
			const result = await ApiManager.DocService.deleteDoc(docId);

			if (result.status === 200) {
				let docIndex = documents.findIndex((el) => el.id === docId);
				documents.splice(docIndex, 1);
				setDocuments([...documents]);
			}
		} catch (error) {
			console.error(error);
		}
	}

	return (
		isLoading ?
			<div className={styles.spinContainer}><Spin /></div>
			:
			<div className={styles.container}>
				<div className={styles.content}>
					<div className={styles.title}>{translation["uploadedDocuments"]}</div>
					<Table
						id="ocr"
						className={styles.table}
						locale={locale}
						dataSource={getTableRows()}
						columns={getTableColumns()}
						rowKey={(record) => `${record.key}`}
						scroll={{x: 'calc(100vw - 332px)'}}
						pagination={{
							position: ['bottomLeft'],
							defaultPageSize: 10,
							total: documents.length,
							showSizeChanger: false,
							itemRender: itemRender
						}}
					/>
				</div>
			</div>
	);
};
