import * as React from "react";
import { PageHeader } from "components/common/presenters/page-header/PageHeader";
import { BackNavigation } from "components/common/presenters/back-navigation/BackNavigation";
import { Document, Page, pdfjs } from 'react-pdf';
import { FileItem } from "components/common/presenters/file-item/FileItem";
import { Table, Input, Button } from "antd";
import { useTranslation } from 'react-i18next';
import { DocumentData } from "types/types";
import { Formatter } from "helpers/Formatter";
import ApiManager from "services/api/ApiManager";
import styles from "./DocumentReviewPage.module.scss";

const { TextArea } = Input;

const OCR_RESULT_TABLE_COLUMN_KEYS = ["other", "otherCorrection", "accountNumber", "accountNumberCorrection", "description", "descriptionCorrection", "subaccount", "subaccountCorrection", "currentYear", "currentYearCorrection", "prevYear", "prevYearCorrection"];

export const DocumentReviewPage = (props) => {
    const documentId = props.match.params.documentId;

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);

    const [loading, setLoading] = React.useState(false);
    const [docData, setDocData] = React.useState<DocumentData>({
		id: "",
		category: "",
		content_type: "",
		country: "",
		created_at: "",
		current_values: "",
		language: "",
		filename: "",
		purpose: "",
		status: "",
		url: "",
	});
    const [docPages, setDocPages] = React.useState([]);
    const [correctionInputFields, setCorrectionInputFields] = React.useState({});
    const [corrections, setCorrections] = React.useState({});
    const [editMode, setEditMode] = React.useState(true);

    const handleInputValueChanged = (newValue, columnId) => {
        let newCorrections = { ...correctionInputFields };
        newCorrections[columnId] = newValue;
        setCorrectionInputFields(newCorrections);
    }

    const getTableColumns = () => {
        let tableColumns = [];
        OCR_RESULT_TABLE_COLUMN_KEYS.forEach(key => {
            tableColumns.push({
                title: (
                    <div className={key === "other" || key === "otherCorrection" || key === "accountNumber" || key === "accountNumberCorrection" ? styles.headerSmall : styles.headerBig}>
                        {translation["correctionsTable"][key]}
                    </div>
                ),
                dataIndex: key,
                key: key,
                render: (text, record) => {
                    return {
                        props: {
                            className: key === "other" || key === "otherCorrection" || key === "accountNumber" || key === "accountNumberCorrection" ? styles.cellSmall : styles.cellBig,
                        },
                        children: text
                    };
                }
            });
        });
        return tableColumns;
    };

    const getTableRows = () => {
        let rows = [];

        docPages.forEach(page => {
            page.Lines.forEach(line => {
                let newLine = { key: line.Id };
                line.Columns.forEach(column => {
                    newLine[Formatter.toCamelCase(column.Category)] = column.Text;
                    newLine[`${Formatter.toCamelCase(column.Category)}Correction`] = <TextArea
                        key={column.Id}
                        className={styles.input}
                        disabled={!editMode}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        value={correctionInputFields[column.Id]}
                        onChange={(event) => { handleInputValueChanged(event.target.value, column.Id); }}
                    />;
                });
                rows.push(newLine);
            });
        });

        return rows;
    };

    const locale = {
        emptyText: translation["noData"]
    };

    const saveCorrections = () => {
        let correctionsTemp = { ...corrections };
        Object.keys(correctionInputFields).forEach(columnId => {
            let value = correctionInputFields[columnId];
            if (value) {
                correctionsTemp[columnId] = value;
            }
        });

        setCorrections(correctionsTemp);

        ApiManager.DocService.updateDocCorrections(documentId, correctionsTemp);
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const docDataResponse = await ApiManager.DocService.getDoc(documentId);
                setDocData(docDataResponse.data.data);
                setCorrections(docDataResponse.data.data.current_values);
                const docResultResponse = await ApiManager.DocService.getDocResult(documentId);
    
                // Initialise correction input fields
                let correctionInputFieldsTemp = {};
                docResultResponse.data.forEach(page => {
                    page.Lines.forEach(line => {
                        line.Columns.forEach(column => {
                            if (docDataResponse.data.data.current_values.hasOwnProperty(column.Id)) {
                                correctionInputFieldsTemp[column.Id] = docDataResponse.data.data.current_values[column.Id];
                            } else {
                                correctionInputFieldsTemp[column.Id] = "";
                            }
                        });
                    });
                });
                setCorrectionInputFields(correctionInputFieldsTemp);
    
                setDocPages(docResultResponse.data);
    
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [documentId]);

    if (loading) {
        return null;
    }
    return (
        <div className={styles.page}>
            <BackNavigation />
            <PageHeader />

            <div className={styles.container}>
                <div className={styles.content}>
                    <FileItem
                        key={docData.id}
                        showJustFileName={true}
                        showBottomBorder={false}
                        fileName={docData.filename}
                    />
                    <div className={styles.documentSection}>
                        <Document className={styles.document} file={docData.url}>
                            {Array.from(new Array(docPages.length), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>

                        <Table
                            id="ocr-result"
                            className={styles.table}
                            locale={locale}
                            dataSource={getTableRows()}
                            columns={getTableColumns()}
                            rowKey={(record) => `${record.key}`}
                            scroll={{ x: "48%" }}
                            pagination={{
                                total: getTableRows().length,
                                pageSize: getTableRows().length,
                                hideOnSinglePage: true
                            }}
                        />

                    </div>
                    {editMode ?
                        <Button
                            type="primary"
                            className={styles.buttonBlue}
                            onClick={() => {
                                setEditMode(false);
                                saveCorrections();
                            }}>Save Results</Button>
                        :
                        <Button type="primary" className={styles.buttonBlue} onClick={() => { setEditMode(true) }}>Edit</Button>
                    }
                </div>
            </div>
        </div>
    );
}