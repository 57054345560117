export const de = {
	de: {
		finscanTitle: "FinCR",
		signUp: "Registrieren",
		finscanLogin: "FinCR Login",
		noAccount: "Don’t have an Account yet?",
		registerHere: "Register here.",
		loginError: "Anmeldung fehlgeschlagen.",
		login: "Weiter",
		toLoginPage: "Zum Login",
		logout: "Ausloggen",
		users: "Users",
		user: "Konto",
		upload: "Upload",
		ocr: "OCR",
		download: "Download",
		documentUpload: "Dokument Hochladen",
		uploadDocumentsPrompt:
			"Dokumente hier hinziehen oder den Button clicken",
		selectFile: "Datei auswählen",
		uploadedDocuments: "Hochgeladene Dokumente",
		noDocumentsUploaded: "Es wurden keine Dokumente hochgeladen.",
		startOcr: "OCR Starten",
		documentName: "Dokumentenname",
		scanDate: "Scan Date",
		results: "Results",
		step: "Schritt",
		progress: "Fortschritt",
		status: "Status",
		action: "Aktion",
		review: "Review",
		noData: "No data",
		next: "Next",
		documentType: "Dokumententyp",
		fincrCreateAccount: "FinCR Account erstellen",
		createAccountDescription:
			"Um die Registrierung abzuschließen, legen Sie bitte ein Passwort für Ihr Benutzerkonto fest.",
		fincrResetPassword: "FinCR Passwort zurücksetzen",
		resetPasswordDescription:
			"Bitte geben Sie ein neues Passwort für ihr FinCR Konto ein.",
		deleteDocModalQuestion: "Möchten Sie dieses Dokument löschen?",
		yes: "Ja",
		no: "Nein",
		adminPanel: {
			finscanAdminLogin: "FinCR Admin Login",
			accountInformation: "Account Information",
			documents: "Documents",
			billing: "Billing",
			customer: "Customer",
			userID: "User Id",
			accountManagement: "Account Management",
			resetPassword: "Reset Password",
			deactivateAccount: "Deactivate Account",
			activateAccount: "Activate Account",
			deleteAccount: "Delete Account",
			noData: "No data",
			next: "Next",
			yes: "Yes",
			no: "No",
			resetPasswordModalQuestion:
				"Are you sure you want to reset this user's password?",
			activateAccountModalQuestion:
				"Are you sure you want to activate this user's account?",
			deactivateAccountModalQuestion:
				"Are you sure you want to deactivate this user's account?",
			deleteAccountModalQuestion:
				"Are you sure you want to delete this user's account?",
			passwordResetSuccessful: "Password reset successfully.",
			passwordResetNotSuccessful: "Could not reset password.",
			activateAccountSuccessful: "Account activated successfully.",
			activateAccountNotSuccessful: "Could not activate account.",
			deactivateAccountSuccessful: "Account deactivated successfully.",
			deactivateAccountNotSuccessful: "Could not deactivate account.",
			deleteAccountSuccessful: "Account deleted successfully.",
			deleteAccountNotSuccessful: "Could not delete account.",
			usersTable: {
				allUsers: "All Users",
				userID: "User number",
				userEmail: "Email",
				userAccountType: "Account Type",
				userCreatedDate: "Created Date",
				userLink: "",
				isRegistered: "Registered",
				isEnabled: "Enabled",
				companyName: "Firma",
			},
		},
		correctionsTable: {
			other: "Other",
			otherCorrection: "Other - Correction",
			accountNumber: "Account No",
			accountNumberCorrection: "Account No - Correction",
			description: "Description",
			descriptionCorrection: "Description - Correction",
			subaccount: "Value (Subaccounts)",
			subaccountCorrection: "Value - Correction",
			currentYear: "Value (Current Year)",
			currentYearCorrection: "Value - Correction",
			prevYear: "Value (Previous Year)",
			prevYearCorrection: "Value - Correction",
		},
		forms: {
			labels: {
				email: "E-Mail",
				password: "Passwort",
				passwordRepeat: "Passwort wiederholen",
			},
			errors: {
				email: "Bitte geben Sie Ihre E-Mail ein.",
				password: "Bitte geben Sie Ihr Passwort ein.",
			},
		},
		errorMessages: {
			passwordDoNotMatch: "Die Passwörter stimmen nicht über ein.",
			termsAndConditionsNotAccepted:
				"Sie müssen die AGB und Datenschutzerklärung akzeptieren um fortzufahren.",
			passwordInvalid:
				"Ihr Passwort muss mindestens 8 Zeichen lang sein, und mindestens 1 Buchstaben, 1 Zahl und 1 Sonderzeichen beinhalten",
			registrationError: "Registrierung fehlgeschlagen.",
			forms: {
				docInvalid: "Es ist nur möglich, PDF Dateien hochzuladen.",
				fileIsDuplicate: "Sie können eine Datei nur einmal hochladen.",
				fileOverMaxSize: "Eine Datei darf maximal 200MB gross sein.",
				maxNumberOfFilesReached:
					"Sie können nur 5 Dateien auf einmal hochladen.",
				docTypeInvalid: "Bitte wählen Sie den Dokumententyp aus.",
			},
		},
		feedbackMessages: {
			uploadSuccessful: "Dateien erfolgreich hochgeladen.",
			userAccountCreatedSuccessful:
				"Ihr Benutzerkonto wurde erfolgreich erstellt.",
			passwordResetSuccessful: "Ihr Passwort wurde erfolgreich geändert.",
			loginPrompt: "Bitte melden Sie sich an, um FinCR zu nutzen.",
		},
		passwordRules: {
			title: "Passwortrichtlinien",
			description: "Ihr Passwort muss mindestens folgendes beinhalten:",
			ruleOne: "- 7 Zeichen",
			ruleTwo: "- 1 Buchstabe",
			ruleThree: "- 1 Zahl",
			ruleFour: "- 1 Sonderzeichen",
		},
		faqTitle: "FAQ",
	},
};
