import React, { useEffect, useState } from "react";
import { Auth, useAuth } from "../hooks/Auth";
import { Redirect, Route } from "react-router-dom";
import { RouteStrings } from "./Routes";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { Spin } from "antd";

export const AuthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
    const auth: Auth = useAuth(false);

    const [isAuthed, setIsAuthed] = useState(null);

    useEffect(() => {
        auth.checkAuth()
            .then((res) => {
                setIsAuthed(res);
                auth.loopRefreshSession();
            })
            .catch(() => setIsAuthed(false))
    }, [C, auth]);

    return (
        <Route
            {...rest}
            render={props => {
                switch (isAuthed) {
                    case null:
                        return <Spin size={"large"} delay={500} />
                    case false:
                        return <Redirect to={{
                            pathname: RouteStrings.Login,
                            state: { reload: true }
                        }}
                        />
                    case true:
                        return (
                            <div>
                                <SideMenu isAdmin={false}/>
                                <C {...props} {...cProps} />
                            </div>
                        )
                    default:
                        return <Spin size={"large"} delay={500} />
                }
            }}
        />
    )
};

export const AuthenticatedAdminRoute = ({ component: C, props: cProps, ...rest }) => {
    const auth: Auth = useAuth(true);

    const [isAuthed, setIsAuthed] = useState(null);

    useEffect(() => {
        auth.checkAuth()
            .then((res) => {
                setIsAuthed(res);
                auth.loopRefreshSession();
            })
            .catch(() => setIsAuthed(false))
    }, [C, auth]);

    return (
        <Route
            {...rest}
            render={props => {
                switch (isAuthed) {
                    case null:
                        return <Spin size={"large"} delay={500} />
                    case false:
                        return <Redirect to={{
                            pathname: RouteStrings.AdminLogin,
                            state: { reload: true }
                        }}
                        />
                    case true:
                        return (
                            <div>
                                <SideMenu isAdmin={true} />
                                <C {...props} {...cProps} />
                            </div>
                        )
                    default:
                        return <Spin size={"large"} delay={500} />
                }
            }}
        />
    )
};
