export const INPUT_VALIDATION_ERRORS = {
    DOCUMENT_UPLOAD: {
        INVALID: "docInvalid",
        DUPLICATE: "fileIsDuplicate",
        FILE_OVER_MAX_SIZE: "fileOverMaxSize",
        MAX_NUMBER_OF_FILES_REACHED: "maxNumberOfFilesReached",
        DOC_TYPE_INVALID: "docTypeInvalid"
    },
    PASSWORD: {
        INVALID: "passwordInvalid"
    }
};