import ApiManager from "./ApiManager";
import { ApiWrapper } from "./ApiWrapper";

const checkUserAuth = async () => {
	const apiCall = ApiWrapper.get("/fu/profile", {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const refreshSession = async () => {
	const path = "/fu/account/refresh_session";

	const apiCall = ApiWrapper.post(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, "");
};

const registerUser = async (token: string, password: string) => {
    const path = "/fu/account/register";

    const body = {
        token: token,
		password: password
    };
    
    const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

	return ApiManager.handleNetworkCallResult(networkCall);
}

const loginUser = async (email: string, password: string) => {
	const path = "/fu/account/login";
	const body = {
		email: email,
		password: password,
	};

	const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const logoutUser = async () => {
	const path = "/fu/account/logout";
	const body = {};

	const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return networkCall;
};

const setNewPassword = async (token: string, password: string) => {
    const path = "/fu/account/recover";

    const body = {
        token: token,
		password: password
    };
    
    const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
    const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

	return ApiManager.handleNetworkCallResult(networkCall);
}

export const UserService = {
	checkUserAuth,
    refreshSession,
    registerUser,
	loginUser,
	logoutUser,
    setNewPassword,
};
