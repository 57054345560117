import * as React from "react";
import { Store, useStore } from "hooks/Store";
import { Upload } from "antd";
import { DOCUMENT_UPLOAD } from "../../../../globals";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { INPUT_VALIDATION_ERRORS } from "helpers/InputFormHelper";
import { InputValidationResult, InputValidator } from "helpers/InputValidator";
import { FileItem } from "components/common/presenters/file-item/FileItem";
import { Button } from "antd";
import { DocumentType } from "types/types";
import { RouteStrings } from "routing/Routes";
import ApiManager from "services/api/ApiManager";
import styles from "./DocumentUpload.module.scss";

const { Dragger } = Upload;

export const DocumentUpload = () => {
	const { i18n } = useTranslation("common");
	const translation = i18n.getDataByLanguage(i18n.language);
	const history = useHistory();
	const store: Store = useStore();

	const errorRef = React.useRef(null);

	const [state, setState] = React.useState({
		hiddenFile: true,
		files: [],
		documentTypes: {},
		fileList: [],
	});

	const [errors, setErrors] = React.useState({
		validationMessage: "",
		feedbackMessage: "",
	});

	React.useEffect(() => {
		if (errors.feedbackMessage || errors.validationMessage) {
			errorRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [errors.validationMessage, errors.feedbackMessage]);

	const setDocumentType = (fileName, documentType) => {
		setState({
			...state,
			documentTypes: {
				...state.documentTypes,
				[fileName]: documentType,
			},
		});
	};

	const onDeleteFile = (file) => {
		const newFiles = state.files.filter((f) => f.name !== file.name);

		setState({
			...state,
			files: newFiles,
		});
	};

	const renderDocuments = () => {
		if (state.files.length === 0) {
			return <div className={styles.infoMessage}>{translation["noDocumentsUploaded"]}</div>;
		}

		let files = [],
			fileNamesAlreadyPushed = [];

		for (let file of state.files) {
			let isDuplicate = false;

			for (let name of fileNamesAlreadyPushed) {
				if (file.name === name) isDuplicate = true;
			}

			if (!isDuplicate) {
				files.push(
					<FileItem
						key={`${file.name}`}
						documentType={state.documentTypes[file.name] ? state.documentTypes[file.name] : DocumentType.None}
						showJustFileName={false}
						showBottomBorder={true}
						fileName={file.name}
						awsId={file.awsId}
						onDelete={() => onDeleteFile(file)}
						onSetDocumentType={setDocumentType}
						visibleForUser={file.visibleForUser}
					/>
				);

				fileNamesAlreadyPushed.push(file.name);
			}
		}

		return files;
	};

	const isFileADuplicate = (fileName: string): boolean => {
		let isDuplicate = false;

		state.files.forEach((f) => {
			if (f.name === fileName) {
				isDuplicate = true;
			}
		});

		return isDuplicate;
	};

	const onFileDrop = async (files) => {
		if (files) {
			const filesToUpload = state.files;

			if (state.files.length + files.length > DOCUMENT_UPLOAD.MAX_ALLOWED_NUMBER_OF_FILES) {
				setErrors({ ...errors, validationMessage: INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.MAX_NUMBER_OF_FILES_REACHED, feedbackMessage: "" });
			} else {
				for (let i = 0; i < files.length; i++) {
					const file = await validateFileToBeUploaded(files[i]);
					if (file != null) {
						file.visibleForUser = !state.hiddenFile;
						filesToUpload.push(file);
					}
				}

				if (filesToUpload.length > 0) {
					setState({
						...state,
						files: filesToUpload,
					});
				}
			}
		}
	};

	/**
	 * Returns undefined if the file is invalid or can't be uploaded for some reason.
	 */
	const validateFileToBeUploaded = (file: any) => {
		// Don't allow upload of duplicate files.
		if (isFileADuplicate(file.name)) {
			setErrors({ ...errors, validationMessage: INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.DUPLICATE, feedbackMessage: "" });
			return;
		}

		const fileTypeValidationResult: InputValidationResult = InputValidator.validateDocType(file.name);

		if (fileTypeValidationResult.validationError) {
			setErrors({ ...errors, validationMessage: INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.INVALID, feedbackMessage: "" });
			return;
		}

		// Show an error if the file is over the max allowed file size
		if (file.size > DOCUMENT_UPLOAD.MAX_ATTACHMENT_SIZE_MB) {
			setErrors({ ...errors, validationMessage: INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.FILE_OVER_MAX_SIZE, feedbackMessage: "" });
			return;
		}

		setErrors({ ...errors, validationMessage: "" });

		return file;
	};

	const uploadFiles = () => {
		if (state.files.length > 0) {
			let error = false;
			let files = [...state.files];

			for (let file of files) {
				let docType = state.documentTypes[file.name];
				if (!docType) {
					error = true;
					setErrors({ ...errors, validationMessage: INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.DOC_TYPE_INVALID, feedbackMessage: "" });
					break;
				}
			}

			if (!error) {
                let successful = true;
				files.forEach(async (file) => {
					let docType = state.documentTypes[file.name];
					const result = await ApiManager.DocService.uploadDoc(store.data.profile.ID, file, docType);
					const uploadUrl = result.data.upload_url;
					fetch(uploadUrl, {
						method: "PUT",
						body: file,
					}).then(async () => {
                        await ApiManager.DocService.setDocUploadSuccessful(result.data.id);
                    }).catch(err => {
                        successful = false;
                    });
				});

				setState({ ...state, files: [], documentTypes: {} });
                if (successful) {
				    setErrors({ ...errors, feedbackMessage: "Upload successful" });
                } else {
                    setErrors({ ...errors, feedbackMessage: "Upload not successful" });
                }

				setTimeout(() => {
					history.push(RouteStrings.HomeOcr);
				}, 50);
			}
		}
	};

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	return (
		<div className={styles.docUpload}>
			<div className={styles.content}>
				<div className={styles.sectionBottom}>
					<div className={styles.documentUploadSection}>
						<div style={{ width: "45%" }}>
							<div className={styles.title}>{translation["documentUpload"]}</div>

							<Dragger
								className={styles.dragDropContainer}
								customRequest={dummyRequest}
								fileList={state.fileList}
								showUploadList={false}
								multiple={true}
								onChange={(info) => {
									const { status } = info.file;
									if (status === "done") {
										if (info.fileList.length > DOCUMENT_UPLOAD.MAX_ALLOWED_NUMBER_OF_FILES) {
											setState({ ...state, fileList: [] });
											setErrors({ ...errors, validationMessage: INPUT_VALIDATION_ERRORS.DOCUMENT_UPLOAD.MAX_NUMBER_OF_FILES_REACHED, feedbackMessage: "" });
										} else {
											onFileDrop([info.file.originFileObj]);
										}
									}
								}}
							>
								<div className={styles.boxIcon}></div>
								<p className={styles.text}>{translation["uploadDocumentsPrompt"]}</p>
								<Button className={styles.buttonWhite}>{translation["selectFile"]}</Button>
							</Dragger>
						</div>

						<div style={{ width: "50%" }}>
							<div className={styles.title}>{translation["uploadedDocuments"]}</div>
							<div className={styles.documentsList}>
								<div className={styles.listInner}>{renderDocuments()}</div>
							</div>

							<div className={styles.message} style={{ color: errors.feedbackMessage ? "grey" : "red" }} ref={errorRef}>
								{translation["feedbackMessages"][errors.feedbackMessage] || translation["errorMessages"]["forms"][errors.validationMessage]}
							</div>

							<Button type="primary" className={styles.buttonBlue} onClick={() => uploadFiles()}>
								{translation["startOcr"]}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
