import { RouteStrings } from "routing/Routes";
import { config } from "../../config";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

/**
 * Wraps around request methods of API AWS Amplify class. This wrapper allows us to insert
 * in 1 place things that need to be into every request.
 */
export class ApiWrapper {
	public static async get(path: any, init: any) {
		if (path == null) {
			return;
		}

		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.get(modifiedPath);
		} catch (error) {
			if (
				error.response &&
				error.response.status === 401 &&
				window.location.pathname !== `${RouteStrings.Login}`
			) {
				window.location.href =
					window.location.origin + `${RouteStrings.Login}`;
				return;
			}
			throw error;
		}
	}

	public static async post(path: any, body: any) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.post(modifiedPath, { ...body });
		} catch (error) {
			if (error.response && error.response.status === 401) {
				// window.location.href = window.location.origin + `${RouteStrings.Login}`;
				return;
			}
			throw error;
		}
	}

	public static async patch(path: any, body: any) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.patch(modifiedPath, { ...body });
		} catch (error) {
			if (error.response && error.response.status === 401) {
				// window.location.href = window.location.origin + `${RouteStrings.Login}`;
				return;
			}
			throw error;
		}
	}

	public static async del(path: any, body: any) {
		const modifiedPath = ApiWrapper.getModifiedPath(path);

		try {
			return await axios.delete(modifiedPath, { ...body });
		} catch (error) {
			if (error.response && error.response.status === 401) {
				// window.location.href = window.location.origin + `${RouteStrings.Login}`;
				return;
			}
			throw error;
		}
	}

	public static getModifiedPath(path: any): any {
		const { USER_SERVICE_URL } = config.api;
		return `${USER_SERVICE_URL}${path}`;
	}
}
