import * as React from "react";
import styles from "./FAQPage.module.scss";

import { PageHeader } from "components/common/presenters/page-header/PageHeader";
import { useTranslation } from "react-i18next";
import { Store, useStore } from "hooks/Store";

import { Collapse } from "antd";

export interface IProps {
	applicationID: string;
}

export const FAQPage: React.FC<IProps> = () => {
	const { i18n } = useTranslation("common");
	const t = i18n.getDataByLanguage(i18n.language);
	const store: Store = useStore();

	const { Panel } = Collapse;

	const faqItems = [
		{
			question: "Where is the data hosted?",
			answer: "All FinCR data is hosted in Frankfurt am Main, Germany, with Amazon Web Services (AWS), which meets all German and European GDPR regulations and the common ISO standards.",
		},
		{
			question: "How is my data encrypted?",
			answer: "All data is encrypted at rest and in transit with BaFin-approved encryption algorithms. Document data and databases are encrypted with 256-bit Advanced Encryption Standard (AES-256). Data in transit is encrypted with strong ciphers via TLSv1.2",
		},
		{
			question: "Who are the data processors?",
			answer: "Besides Teylor AG, the data processors are:\nAWS\nGoogle Cloud\nMailgun (no document data, but processes emails including user invites or forgot password)\nDatadog (no document data, but logs and technical performance/monitoring data only)",
		},
		{
			question: "What document types does FinCR support?",
			answer: "FinCR was designed specifically for the financial services industry and is trained to scan annual statements and interim statements of German companies (“Jahresabschlüsse”, “BWA”, “SuSa”). Other document types are available upon request. For more information on what other types of documents FinCR may support please contact your account manager.",
		},
		{
			question: "What document formats does FinCR support?",
			answer: "Users can upload documents in PDF format. Uploading documents in other formats is only possible in the API-based premium version, but not in the standard browser-implementation or for test accounts. To unlock other document types, please contact your account manager.",
		},
		{
			question: "What is FinCR’s output format?",
			answer: "The output format of the standard browser-based version is either JSON or XLSX (Excel). Via a premium API-implementation, users can also get output formats in XML, XBLR, CSV and other formats. For formats other than JSON or XLSX please contact your account manager.",
		},
		{
			question: "What is the output document’s structure? ",
			answer: "One of the key features of Teylor FinCR is that it reads data from uploaded documents and returns it in consistent formats. This means that even if 10 different documents made by 10 different companies using 10 different software solutions are uploaded simultaneously, the output will be in the same structure every time. This allows users to build their own processes on top of the FinCR API or user interface, for example by automatically calculating KPIs or loading relevant data into an ERP system.",
		},
		{
			question:
				"What types of annual reports (Jahresabschlüsse) does FinCR not support?",
			answer: "FinCR might in some cases ignore documents where the “Einzelkontennachweis” is primarily in written form without a tabular structure. FinCR might also ignore any financial documents that are not in a standard HGB or similar format. This is generally  the case for documents that are simply excel documents or self-made documents that have not been created by or with a tax advisor or accounting software.",
		},
		{
			question: "How many documents can I process at once?",
			answer: "The basic test account can process up to 5 documents simultaneously. Contact your account manager if you wish to increase the processing rate.",
		},
		{
			question: "How long does it take to process a document?",
			answer: "Processing time depends on the size, quality and layout of the document. Large documents in uncommon formats can take up to 100 seconds to process. These are documents where the scans are of inferior quality, pages have different orientations or skews and with 60+ pages. A simple 1-page BWA generally takes only a few seconds.",
		},
		{
			question: "Is there an API?",
			answer: "There is a Rest API available for premium users. If you would like to gain access to the API please contact your account manager.",
		},
		{
			question: "What pricing options are available?",
			answer: "Please contact your account manager to discuss the various pricing options.",
		},
		{
			question: "How long will Teylor store my data?",
			answer: "All customer data is deleted after 30 days. It is possible to set custom deletion schedules to match other data processing guidelines. To do this, please contact your account manager.",
		},
		{
			question: "Can I add new users?",
			answer: "In the test account, only Teylor staff can add users.\nFull accounts are able to add users either via the API or via the user interface. The admin account can add new users and new users must be associated with an organisation. To do this in the full version, please log in the account and click on the user management tab on the left side of the screen.",
		},
		{
			question: "How do I delete my account?",
			answer: "Users can only delete their account through the user interface or by contacting their account manager, not through the API. To do this please log in the account and click on the settings tab on the left side of the screen and follow the instructions. Test users can only delete documents not accounts. To delete a test account please contact your account manager.",
		},
		{
			question: "What happens to my data after I delete my account?",
			answer: "All data is immediately deleted.",
		},
		{
			question: "How can I change my email address and password?",
			answer: "To change the email address or password associated with your test account please contact your account manager.",
		},
		{
			question:
				"Is it possible to provide feedback on the results and train my own models?",
			answer: "Yes, but this feature is not available through the interface, only through the API. For the time being, only certain users and certain use cases can provide feedback and train their own models. To find out more please speak to your account manager.",
		},
		{
			question: "Does FinCF show a confidence interval for the results?",
			answer: "Through the API it is possible to receive a confidence interval - although this is not a core feature and needs to be specifically requested. In the frontend interface users are only shown results with a high confidence level. The system is set to rather show nothing than to show a wrong result.",
		},
		{
			question: "Is FinCR GDPR compliant?",
			answer: "Yes, FinCR is fully GDPR compliant and all data is managed in compliance with GDPR.",
		},
	];

	const renderFAQs = () => {
		const panels: JSX.Element[] = []; // is this the best way?
		for (let i = 0; i < faqItems.length; i++) {
			panels.push(
				<Panel
					header={faqItems[i].question}
					key={i}
					className={styles.faqQuestion}
					style={{ color: "red" }}
				>
					<p className={styles.faqAnswer}>{faqItems[i].answer}</p>
				</Panel>
			);
		}

		return <Collapse ghost>{panels}</Collapse>;
	};

	return (
		<>
			<div className={styles.page}>
				<PageHeader
					title={t["finscanTitle"]}
					subtitle={`${t["user"]}: ${store.data.profile.Email}`}
				/>
				<div className={styles.docUpload}>
					<div className={styles.content}>
						<div className={styles.sectionBottom}>
							<div className={styles.faqSection}>
								<div>
									<div className={styles.title}>
										{t["faqTitle"]}
									</div>
									<div>{renderFAQs()}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
