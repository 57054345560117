import ApiManager from "./ApiManager";
import { ApiWrapper } from "./ApiWrapper";

const checkUserAuth = async () => {
	const apiCall = ApiWrapper.get("/admin/profile", {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const refreshSession = async () => {
	const path = "/admin/account/refresh_session";

	const apiCall = ApiWrapper.post(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, "");
};

const loginUser = async (email: string, password: string) => {
	const path = "/admin/account/login";
	const body = {
		email: email,
		password: password,
	};

	const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const logoutUser = async () => {
	const path = "/admin/account/logout";
	const body = {};

	const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return networkCall;
};

const getAllUsers = async (page, limit, emailSearchQuery?: string) => {
    let path = `/admin/fusers?page=${page}&limit=${limit}`;
    
    if (emailSearchQuery) {
        path += `&email=${emailSearchQuery}`;
    }

	const apiCall = ApiWrapper.get(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall);
};

const getUserById = async (userId) => {
	const apiCall = ApiWrapper.get(`/admin/fusers/${userId}`, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const resetUserPassword = async (userId, userEmail) => {
    const path = `/admin/fusers/${userId}/reset`;
    const body = {
        email: userEmail
    };

	const apiCall = ApiWrapper.post(path, body);

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const disableUserAccount = async (userId) => {
    const path = `/admin/fusers/${userId}/disable`;

	const apiCall = ApiWrapper.patch(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const enableUserAccount = async (userId) => {
    const path = `/admin/fusers/${userId}/enable`;

	const apiCall = ApiWrapper.patch(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const deleteUserAccount = async (userId) => {
    const path = `/admin/fusers/${userId}/delete`;

	const apiCall = ApiWrapper.del(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
};

const getDocsForUser = async (userId) => {
    const path = `/admin/fusers/${userId}/documents`;

	const apiCall = ApiWrapper.get(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
}

const getPdfDownloadUrl = async (userId, docId) => {
    const path = `/admin/fusers/${userId}/documents/${docId}/pdf`;

	const apiCall = ApiWrapper.get(path, {});

	// One of the two promises will resolve first.
	const networkCall = await Promise.race([
		apiCall,
		ApiManager.generateTimeout(),
	]);

	return ApiManager.handleNetworkCallResult(networkCall, null);
}

export const AdminService = {
	checkUserAuth,
    refreshSession,
    loginUser,
    logoutUser,
    getAllUsers,
    getUserById,
    resetUserPassword,
    disableUserAccount,
    enableUserAccount,
    deleteUserAccount,
    getDocsForUser,
    getPdfDownloadUrl,
};
