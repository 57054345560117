import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './UserAccountManagement.module.scss';
import { Button, Popconfirm, message } from 'antd';
import ApiManager from "services/api/ApiManager";

export const UserAccountManagement = (props) => {
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language)["adminPanel"];

    const [enabled, setEnabled] = useState(props.isEnabled);

    const handlePasswordReset = async () => {
        const result = await ApiManager.AdminService.resetUserPassword(props.userId, props.userEmail);
        if (result.status === 200) {
            message.success(translation["passwordResetSuccessful"]);
        } else {
            message.error(translation["passwordResetNotSuccessful"]);
        }
    }

    const handleDeactivateAccount = async () => {
        const result = await ApiManager.AdminService.disableUserAccount(props.userId);
        if (result.status === 200) {
            setEnabled(false);
            message.success(translation["deactivateAccountSuccessful"]);
        } else {
            message.error(translation["deactivateAccountNotSuccessful"]);
        }
    }

    const handleActivateAccount = async () => {
        const result = await ApiManager.AdminService.enableUserAccount(props.userId);
        if (result.status === 200) {
            setEnabled(true);
            message.success(translation["activateAccountSuccessful"]);
        } else {
            message.error(translation["activateAccountNotSuccessful"]);
        }
    }

    const handleDeleteAccount = async () => {
        const result = await ApiManager.AdminService.deleteUserAccount(props.userId);
        if (result.status === 200) {
            message.success(translation["deleteAccountSuccessful"]);
        } else {
            message.error(translation["deleteAccountNotSuccessful"]);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{translation['accountManagement']}</div>
            <Popconfirm
                title={<div className={styles.confirmationDialogueText}>{translation['resetPasswordModalQuestion']}</div>}
                okText={<div className={styles.confirmationDialogueText} style={{ color: 'white' }}>{translation['yes']}</div>}
                cancelText={<div className={styles.confirmationDialogueText}>{translation['no']}</div>}
                onConfirm={handlePasswordReset}
            >
                <Button type="primary" className={styles.button}>
                    {translation['resetPassword']}
                </Button>
            </Popconfirm>
            <Popconfirm
                title={<div className={styles.confirmationDialogueText}>{enabled ? translation['deactivateAccountModalQuestion'] : translation['activateAccountModalQuestion']}</div>}
                okText={<div className={styles.confirmationDialogueText} style={{ color: 'white' }}>{translation['yes']}</div>}
                cancelText={<div className={styles.confirmationDialogueText}>{translation['no']}</div>}
                onConfirm={enabled ? handleDeactivateAccount : handleActivateAccount}
            >
                <Button type="primary" className={styles.button}>
                    {enabled ? translation['deactivateAccount'] : translation['activateAccount']}
                </Button>
            </Popconfirm>
            <Popconfirm
                title={<div className={styles.confirmationDialogueText}>{translation['deleteAccountModalQuestion']}</div>}
                okText={<div className={styles.confirmationDialogueText} style={{ color: 'white' }}>{translation['yes']}</div>}
                cancelText={<div className={styles.confirmationDialogueText}>{translation['no']}</div>}
                onConfirm={handleDeleteAccount}
            >
                <Button danger type="primary" className={styles.button}>
                    {translation['deleteAccount']}
                </Button>
            </Popconfirm>
        </div>
    );
}