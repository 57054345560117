import * as React from "react";
import { useTranslation } from 'react-i18next';
import { FileOutlined, DownOutlined } from '@ant-design/icons';
import styles from "./FileItem.module.scss";
import { DocumentType } from "types/types";
import { DOCUMENT_TYPE_NAMES } from "../../../../globals";
import { Menu, Dropdown } from "antd";

export interface FileItemProps {
    className?: string;
    awsId?: string;
    isLoading?: boolean;
    fileName: string;
    documentType: DocumentType;
    onDelete: () => void;
    onDownloadClicked?: () => void;
    onSetDocumentType?: () => void;
}

export const FileItem = (props) => {
    const { i18n } = useTranslation("common");
    const translation = i18n.getDataByLanguage(i18n.language);

    const menu = (
        <Menu>
            <Menu.Item key="0" className={styles.menuItem} onClick={() => { props.onSetDocumentType(props.fileName, DocumentType.AnnualStatement) }}>
                {DOCUMENT_TYPE_NAMES[DocumentType.AnnualStatement]}
            </Menu.Item>
            <Menu.Item key="1" className={styles.menuItem} onClick={() => { props.onSetDocumentType(props.fileName, DocumentType.BWA_SUSA) }}>
                {DOCUMENT_TYPE_NAMES[DocumentType.BWA_SUSA]}
            </Menu.Item>
        </Menu>
    );

    return (
        <div className={styles.fileItem} style={{ borderBottom: props.showBottomBorder ? "1px solid #e3e5ea" : "none" }}>
            <FileOutlined className={styles.icon} />
            <div className={styles.name}>{props.fileName}</div>
            { props.showJustFileName ?
                null
                :
                <div className={styles.buttons}>
                    <Dropdown overlay={menu} trigger={["click"]}>
                        {props.documentType ?
                            <div className={styles.docType}>{DOCUMENT_TYPE_NAMES[props.documentType]}</div>
                            :
                            <div className={styles.docType}>{translation["documentType"]} <DownOutlined /></div>
                        }
                    </Dropdown>
                    <div className={props.visibleForUser ? styles.visible : styles.hidden} />
                    <div className={styles.delete} onClick={() => { props.onDelete(); }}></div>
                </div>
            }
        </div>
    );
};
