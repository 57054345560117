import React from 'react';
import { StoreContextProvider } from "./hooks/Store";
import { Routes } from './routing/Routes';

class App extends React.Component<any, any> {

    public render() {
        return (<div className="App app-container">
            <ErrorBoundary>
                <StoreContextProvider>
                    <Routes />
                </StoreContextProvider>
            </ErrorBoundary>
        </div>);
    }
}

export default App;


class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    private static getDerivedStateFromError(error) {
        console.error(error);
        return { hasError: true };
    }

    public componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}