import * as React from "react";
import styles from "./PageHeader.module.scss";
import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as ClipboardJS from "clipboard";

export const PageHeader = (props) => {
    new ClipboardJS('#copyButton');

    return (
        <div className={styles.pageHeader}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.subtitleContainer}>
                <div className={styles.subtitle}>{props.subtitle}</div>

                {props.copySubtitle ?
                    <div className={styles.copyContainer}>
                        <div className={styles.subtitle} id="copyText">{props.copyText}</div>
                        <Button id="copyButton" data-clipboard-target="#copyText" type="link" icon={<CopyOutlined className={styles.copy} />} />
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );

};
