import React, { createContext, useContext, useState } from "react";
import { UserProfile } from "types/types";

export interface Store {
	data: StoreData;
	setData: (data: StoreData) => void;
	isEmpty: () => boolean;
	reset: () => void;
}

export interface StoreData {
	profile?: UserProfile;
}

const initialState: StoreData = {
	profile: null,
};

export const StoreContextProvider = ({ children }) => {
    const [state, setState] = useState(initialState)

    const data: StoreData = { // returning a deep copy, so state can only be modified via setData() from the outside world
        profile: { ...state.profile }
    }

    const setData = (newData: StoreData) => {
        setState(prevState => {
            return { ...prevState, ...newData }
        })
    }

    const isEmpty = (): boolean => {
        return state === initialState
    }

    const reset = () => {
        setState({
            ...initialState
        })
    }

    const ctxValue: Store = {
        data,
        setData,
        isEmpty,
        reset,
    }

    return (
        <storeContext.Provider value={ctxValue}>
            {children}
        </storeContext.Provider>
    )
}

const storeContext = createContext(null as Store);

export const useStore = (): Store => {
	const store = useContext(storeContext);

	return store;
};
