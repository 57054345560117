import momenttz from 'moment-timezone';

export class Formatter {
    public static toCamelCase(text: string): string {
        return text.toLowerCase().replace(/_(.)/g, function (match, group) {
            return group.toUpperCase();
        });
    }

    public static formatTimestampIntoZurichTime = (timestamp: number | string): string => {
        const zurichTimeFormatted = momenttz(timestamp).tz("Europe/Zurich").format("DD-MM-YYYY HH:mm:ss");
    
        return zurichTimeFormatted;
    };
}